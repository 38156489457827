// frontend-admin/src/App.js

import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Login from './pages/Login';
import ApplicationsList from './components/ApplicationsList';
import ApplicationDetail from './components/ApplicationDetail';
import Navbar from './components/Navbar';

const App = () => {
  const isAuthenticated = !!localStorage.getItem('token');

  return (
    <Router>
      <Navbar />
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route
          path="/applications"
          element={
            isAuthenticated ? <ApplicationsList /> : <Navigate to="/login" replace />
          }
        />
        <Route
          path="/applications/:id"
          element={
            isAuthenticated ? <ApplicationDetail /> : <Navigate to="/login" replace />
          }
        />
        <Route
          path="/"
          element={<Navigate to="/applications" replace />}
        />
      </Routes>
    </Router>
  );
};

export default App;
