// frontend-admin/src/components/ApplicationsList.js

import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Table, TableBody, TableCell, TableHead, TableRow, Button, Typography, Container } from '@mui/material';
import { Link } from 'react-router-dom';

const ApplicationsList = () => {
  const [applications, setApplications] = useState([]);
  const [error, setError] = useState('');

  const fetchApplications = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get('/api/applications', {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });
      setApplications(response.data);
    } catch (err) {
      console.error('Ошибка при получении заявок:', err);
      setError('Не удалось загрузить заявки.');
    }
  };

  useEffect(() => {
    fetchApplications();
  }, []);

  return (
    <Container>
      <Typography variant="h4" gutterBottom>
        Заявки
      </Typography>
      {error && <Typography color="error">{error}</Typography>}
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Имя клиента</TableCell>
            <TableCell>Email</TableCell>
            <TableCell>Телефон</TableCell>
            <TableCell>Сообщение</TableCell>
            <TableCell>Статус</TableCell>
            <TableCell>Дата создания</TableCell>
            <TableCell>Действия</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {applications.map(app => (
            <TableRow key={app._id}>
              <TableCell>{app.clientName}</TableCell>
              <TableCell>{app.clientEmail}</TableCell>
              <TableCell>{app.clientPhone}</TableCell>
              <TableCell>{app.message}</TableCell>
              <TableCell>{app.status}</TableCell>
              <TableCell>{new Date(app.createdAt).toLocaleString()}</TableCell>
              <TableCell>
                <Button component={Link} to={`/applications/${app._id}`} variant="outlined">Просмотр</Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Container>
  );
};

export default ApplicationsList;
