// frontend-admin/src/components/ApplicationDetail.js

import React, { useEffect, useState, useCallback } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { Container, Typography, TextField, Button, Select, MenuItem, FormControl, InputLabel, Box, List, ListItem, ListItemText } from '@mui/material';

const ApplicationDetail = () => {
  const { id } = useParams();
  const [application, setApplication] = useState(null);
  const [status, setStatus] = useState('');
  const [commentText, setCommentText] = useState('');
  const [error, setError] = useState('');

  const fetchApplication = useCallback(async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`/api/applications/${id}`, {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });
      setApplication(response.data);
      setStatus(response.data.status);
    } catch (err) {
      console.error('Ошибка при получении заявки:', err);
      setError('Не удалось загрузить детали заявки.');
    }
  }, [id]);

  useEffect(() => {
    fetchApplication();
  }, [fetchApplication]);

  const handleStatusChange = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.patch(`/api/applications/${id}/status`, { status }, {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });
      setApplication(response.data.application);
      console.log('Статус обновлен');
    } catch (err) {
      console.error('Ошибка при обновлении статуса:', err);
      setError('Не удалось обновить статус.');
    }
  };

  const handleAddComment = async () => {
    if (!commentText) return;

    try {
      const token = localStorage.getItem('token');
      const response = await axios.post(`/api/applications/${id}/comments`, { text: commentText }, {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });
      setApplication(prev => ({
        ...prev,
        comments: [...prev.comments, response.data.comment],
      }));
      setCommentText('');
      console.log('Комментарий добавлен');
    } catch (err) {
      console.error('Ошибка при добавлении комментария:', err);
      setError('Не удалось добавить комментарий.');
    }
  };

  if (error) {
    return <Container><Typography color="error">{error}</Typography></Container>;
  }

  if (!application) {
    return <Container><Typography>Загрузка...</Typography></Container>;
  }

  return (
    <Container>
      <Typography variant="h4" gutterBottom>
        Детали заявки
      </Typography>
      <Typography variant="h6">Информация о клиенте</Typography>
      <Typography><strong>Имя:</strong> {application.clientName}</Typography>
      <Typography><strong>Email:</strong> {application.clientEmail}</Typography>
      <Typography><strong>Телефон:</strong> {application.clientPhone}</Typography>
      <Typography variant="h6" sx={{ mt: 2 }}>Сообщение клиента</Typography>
      <Typography>{application.message}</Typography>
      <Box sx={{ mt: 2 }}>
        <FormControl fullWidth>
          <InputLabel id="status-label">Статус</InputLabel>
          <Select
            labelId="status-label"
            value={status}
            label="Статус"
            onChange={(e) => setStatus(e.target.value)}
          >
            <MenuItem value="new">Новая</MenuItem>
            <MenuItem value="in_progress">В работе</MenuItem>
            <MenuItem value="completed">Завершена</MenuItem>
            <MenuItem value="declined">Отклонена</MenuItem>
          </Select>
        </FormControl>
        <Button variant="contained" sx={{ mt: 2 }} onClick={handleStatusChange}>
          Обновить статус
        </Button>
      </Box>
      <Box sx={{ mt: 4 }}>
        <Typography variant="h6">Комментарии</Typography>
        <List>
          {application.comments.map((comment, index) => (
            <ListItem key={index}>
              <ListItemText
                primary={comment.text}
                secondary={`${comment.author} - ${new Date(comment.createdAt).toLocaleString()}`}
              />
            </ListItem>
          ))}
        </List>
        <Box sx={{ mt: 2 }}>
          <TextField
            label="Ваш комментарий"
            multiline
            rows={4}
            value={commentText}
            onChange={(e) => setCommentText(e.target.value)}
            fullWidth
          />
          <Button variant="contained" sx={{ mt: 2 }} onClick={handleAddComment}>
            Добавить комментарий
          </Button>
        </Box>
      </Box>
    </Container>
  );
};

export default ApplicationDetail;
